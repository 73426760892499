<template>
  <div class="container">
    <div
      v-show="isTournamentButtonVisible"
      role="button"
      :class="{ container__tournament: booleanIsTournamentsModalOpen }"
      :style="{ 'background-color': booleanIsTournamentsModalOpen ? '#132739' : 'white' }"
      @click="onClickTournament"
    >
      <template v-if="!booleanIsTournamentsModalOpen">
        <img class="logo" :src="leagueLogo" alt="" />
        <div>{{ objectTournamentSelected.name }}</div>
      </template>
      <template v-else>
        <div class="close-text">
          CERRAR
        </div>
        <div class="close-icon">
          <img src="/images/icn_close.png" />
        </div>
      </template>
    </div>
    <div
      role="button"
      class="container__team"
      :class="{ 'is-team-only': !isTournamentButtonVisible, inactive: !this.booleanAreLiveGames }"
      @click="onClickTeam"
    >
      <div>
        LIVE
      </div>
      <img class="logo" src="/images/menu/icn_live.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'NavBarTournamentButtonMobile',
  computed: {
    ...mapState('loginGeneral', ['user']),
    ...mapState('leagueGeneral', [
      'objectTournamentSelected',
      'objectSeasonSelected',
      'booleanIsTournamentsModalOpen',
      'booleanAreLiveGames',
    ]),
    isTournamentButtonVisible() {
      return !['players', 'playerProfile', 'teamProfile'].includes(this.$route.name);
    },
    leagueLogo() {
      if (this.objectTournamentSelected.id === 33) {
        return '/images/leagues/' + this.objectSeasonSelected.id + '.png';
      } else {
        return '/images/leagues/' + this.objectTournamentSelected.id + '.png';
      }
    },
    imgLogo() {
      if (this.user.player !== 0) {
        return 'https://az755631.vo.msecnd.net/players-profile/' + this.user.player + '.png';
      } else {
        return 'https://az755631.vo.msecnd.net/teams-80/' + this.user.team + '.png';
      }
    },
    hasActiveTime() {
      return this.user.active_time === 1;
    },
    isPlayer() {
      return this.user.player !== 0;
    },
  },
  methods: {
    ...mapMutations('leagueGeneral', ['setBooleanIsTournamentsModalOpen', 'setBooleanIsLiveGamesModalOpen']),
    onClickTournament() {
      this.setBooleanIsTournamentsModalOpen(!this.booleanIsTournamentsModalOpen);
    },
    onClickTeam() {
      this.setBooleanIsLiveGamesModalOpen(true);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  padding: 0 8px;

  & img.logo {
    height: 32px;
    @media screen and (max-width: 320px) {
      margin: 0 -5px;
      width: fit-content;
    }
  }

  &__tournament {
    z-index: 16;

    & > div:last-child {
      padding-top: 0.3em;
    }
  }

  &__team {
    cursor: pointer;

    & > div:first-child {
      padding-top: 0.3em;
    }
    & > img.logo {
      margin: 0 5px;
      height: 32px;
    }

    &.inactive {
      cursor: none;
      background-color: #e8e8e8;
      color: #c9ccce;

      & > img.logo {
        filter: grayscale(1);
      }
    }
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: solid 1px #b7b7b7;
    font-family: Avenir-Demi, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.12px;
    color: #132739;
    cursor: pointer;
    padding: 8px 10px;
    max-height: 4.16em;
    height: 4.16em;

    & > div {
      margin: 0 5px;
      white-space: pre;
    }
  }

  @media screen and (max-width: 390px) {
    padding: 0 2px;
    column-gap: 8px;
  }
}

.close-text {
  font-family: Avenir-Bold, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #fff;
}

.close-icon {
  height: 15px;
  width: 15px;

  & > img {
    height: 15px;
    width: 15px;
  }
}
.no-dashboard:hover {
  cursor: default !important;
  opacity: 1 !important;
}

.is-team-only {
  grid-column-start: 2;
  grid-column-end: 3;
}
</style>
